import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, Formik } from "formik";
import { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Button from 'react-bootstrap/Button';
import { useHistory } from "react-router";
import * as yup from 'yup';
import APIPostFailed from "../../APIFailed/APIPostFailed";
import { authService } from "../../services/auth-service";
import { LoginContext } from "../../services/LoginContext";

function AddCompetitionResult(props) {

    class GroupResult {
        age = "";
        subgroup = "";
        results = [1, 2, 3].map(e => ({
            place: e,
            player: "",
            city: ""
        }))
    }

    const schema = yup.object().shape({
        tName: yup.string().required("Введите название турнира").max(120, "Название турнира не должно превышать 120 символов"),
        year: yup.number().required().max(new Date().getFullYear()),
        groups: yup.array().of(yup.object({
            age: yup.string().required("Укажите возрастную группу").max(30),
            subgroup: yup.string().nullable().max(30),
            results: yup.array().of(yup.object({
                place: yup.number().oneOf([1, 2, 3]),
                player: yup.string().required("Введите имя участника"),
                city: yup.string().nullable().max(50)
            })).required().length(3)
        })).min(1),
        photosLinks: yup.array().of(yup.string().ensure().matches(/^https?[:][/]{2}\S+$/, "Неверный формат ссылки")),
        pictures: yup.array().of(yup.mixed().test("MIME Type", "Вы добавили файл, не являющийся фотографией", (value, context) => value.type.startsWith("image/"))).max(3, "Вы прикрепили более 3 фотографий")
    })


    const rowColors = {
        1: "alert-success",
        2: "alert-secondary",
        3: "alert-warning"
    }

    const subGroupHelpText = "Если пол участников указан, результаты будут сгруппированы по возрастной группе, затем по полу.";

    const setLoggedIn = useContext(LoginContext)[1];
    const [fetchFailed, setFetchFailed] = useState();
    const [errorMsg, setErrorMsg] = useState();

    const history = useHistory();

    const submitResults = (formData, setSubmitting) => {
        fetch(`${process.env.REACT_APP_API_URL}/competition`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authService.authHeader()
            },
            body: JSON.stringify(formData, (name, value) => {
                if (name === 'pictures') {
                    return value.map(e => e.name)
                } else {
                    return value;
                }
            })
        })
            .then(response => response.json().then(data => ({ status: response.status, data: data })))
            .then(({ status, data }) => {
                if (status === 201) {
                    let message;
                    Promise.all(data.map((mapping, index) => {
                        return (fetch(mapping.url, {
                            method: 'PUT',
                            mode: 'cors',
                            body: formData.pictures[index]
                        }))
                    }))
                        .then((responses) => {
                            if (!responses)
                                message = "Результаты соревнования были успешно добавлены"
                            else if (responses.every(r => r.status >= 200 && r.status < 300))
                                message = "Результаты соревнования были успешно добавлены"
                            else
                                message = "Результаты соревнования были успешно добавлены. Не удалось загрузить прикрепленные фотографии"

                        })
                        .catch(err => {
                            message = "Результаты соревнования были успешно добавлены. Не удалось загрузить прикрепленные фотографии"
                        })
                        .finally(() => {
                            setSubmitting(false);
                            let currDate = new Date();
                            props.createToast({
                                title: "Добавить результаты соревнований",
                                time: `${currDate.getHours()}:${('0' + currDate.getMinutes()).slice(-2)}`,
                                message: message,
                                show: true
                            });
                            history.push('/siteadmin/home')
                        })
                } else if (status === 401) {
                    setSubmitting(false);
                    authService.logout()
                        .then(() => setLoggedIn(false));
                } else {
                    setSubmitting(false);
                    setFetchFailed(true)
                    setErrorMsg(data.error || "Произошла неизвестная ошибка.")
                }
            })
            .catch(err => {
                setSubmitting(false);
                setFetchFailed(true)
                setErrorMsg("Произошла неизвестная ошибка.")
                console.log(err)
            })
    }

    return (
        <Container className="siteadmin-action-container">
            <h1 className="siteadmin-action-heading">Добавить результаты соревнований</h1>
            <Formik
                initialValues={{
                    tName: "",
                    year: new Date().getFullYear(),
                    groups: [new GroupResult()],
                    photosLinks: [],
                    pictures: []
                }}
                validationSchema={schema}
                validateOnBlur={true}
                onSubmit={(values, { setSubmitting }) => submitResults(values, setSubmitting)}
            >
                {
                    ({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        values,
                        touched,
                        isSubmitting,
                        errors

                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <h3>Сведения о турнире</h3>
                            <Row xs={1}>
                                <Form.Group as={Col} md={10} className="mb-3" controlId="tName">
                                    <Form.Label>Название турнира</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="tName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={!!errors.tName && touched.tName}
                                        placeholder="Введите название турнира"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.tName}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md={2} className="mb-3" controlId="year">
                                    <Form.Label>Год проведения</Form.Label>
                                    <Form.Select
                                        name="year"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={!!errors.year}
                                    >
                                        {
                                            [new Date().getFullYear(), new Date().getFullYear() - 1].map((e, index) => {
                                                return (
                                                    <option key={index} value={e}>{e}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.year}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <h3 className="mb-0">Результаты</h3>
                                <Form.Text>Добавьте нужное количество групп и заполните результаты для каждой</Form.Text>
                            </Row>
                            <FieldArray
                                name="groups"
                                validateOnChange={false}
                                render={arrayHelpers => (
                                    <div>
                                        {
                                            values.groups.map((e, index) => (
                                                <Row key={`gr${index}`} xs={1} style={{ marginBottom: "2em" }}>
                                                    <Col md={2} className="d-flex flex-column justify-content-around">
                                                        <div style={{ textAlign: "center", fontSize: "1.5em" }}>
                                                            <FontAwesomeIcon icon={faTrashAlt} onClick={() => arrayHelpers.remove(index)} />
                                                        </div>
                                                    </Col>

                                                    <Col md={10}>
                                                        {e.age.length > 0 && <h4>{e.age} {e.subgroup.length > 0 && <small className="text-muted">{e.subgroup}</small>}</h4>}
                                                        <Row className="mb-3">
                                                            <Form.Group as={Col} md={6} controlId={`groups[${index}].age`}>
                                                                <Form.Label>Возрастная группа</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`groups[${index}].age`}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={!!errors.groups?.[index]?.age && touched.groups?.[index]?.age}
                                                                    placeholder="Введите возраст участников"
                                                                />
                                                                <Form.Text>Например: До 15 лет</Form.Text>
                                                                <Form.Control.Feedback type="invalid">{errors.groups?.[index]?.age}</Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md={6} controlId={`groups[${index}].subgroup`}>
                                                                <Form.Label>Пол участников (не обязательно)</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name={`groups[${index}].subgroup`}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={!!errors.groups?.[index]?.subgroup && touched.groups?.[index]?.subgroup}
                                                                    placeholder="Введите пол участников"
                                                                />
                                                                <Form.Text>{subGroupHelpText}</Form.Text>
                                                                <Form.Control.Feedback type="invalid">{errors.groups?.[index]?.subgroup}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col md={8}>
                                                                <Row>
                                                                    <Col md={3} />
                                                                    <Col md={5}><span className="ms-1">Имя</span></Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={4}><span className="ms-3">Город (не обязательно)</span></Col>
                                                        </Row>

                                                        {
                                                            e.results.map((r, i) => (
                                                                <Row key={`gr${index}pl${i}`} className="mb-3">
                                                                    <Col md={8}>
                                                                        <InputGroup hasValidation>
                                                                            <InputGroup.Text className={`col-md-2 ${rowColors[r.place]}`}>{r.place} место</InputGroup.Text>
                                                                            <Form.Control className="col-md-5"
                                                                                type="text"
                                                                                name={`groups[${index}].results[${i}].player`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                isInvalid={!!errors.groups?.[index]?.results?.[i]?.player && touched.groups?.[index]?.results?.[i]?.player}
                                                                                placeholder="Введите имя"
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">{errors.groups?.[index]?.results?.[i]?.player}</Form.Control.Feedback>
                                                                        </InputGroup>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <Form.Control className="col-md-4 ms-2"
                                                                            type="text"
                                                                            name={`groups[${index}].results[${i}.city`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            isInvalid={!!errors.groups?.[index]?.results?.[i]?.city && touched.groups?.[index]?.results?.[i]?.city}
                                                                            placeholder="Введите город"
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">{errors.groups?.[index]?.results?.[i]?.city}</Form.Control.Feedback>
                                                                    </Col>
                                                                </Row>

                                                            ))
                                                        }
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                        {/* This button adds an empty row to form when clicked */}
                                        <Button
                                            variant="success"
                                            onClick={() => arrayHelpers.push(new GroupResult())}
                                            style={{ marginBottom: "2em" }}
                                        >
                                            <strong>+ </strong>Добавить группу
                                        </Button>
                                    </div>
                                )}
                            />
                            <Row className="mb-4">
                                <h3 className="mb-0">Фотографии</h3>
                                <Form.Text>Загрузите фотографии и/или прикрепите ссылки</Form.Text>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <h4 className="mb-0 ">Загрузка фотографий</h4>
                                    <Form.Text>Эти фотографии появятся на сайте</Form.Text>
                                    <Form.Group className="mt-2">
                                        <Form.Label>Приложите до 3 фотографий</Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="pictures"
                                            onChange={evt => setFieldValue("pictures", Array.from(evt.currentTarget.files), true)}
                                            onBlur={handleBlur}
                                            isInvalid={!!errors.pictures && touched.pictures}
                                            multiple
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.pictures}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <h4 className="mb-0">Прикрепите ссылки</h4>
                                    <Form.Text>Добавьте нужное количество ссылок, нажав на «+»</Form.Text>
                                    <FieldArray
                                        name="photosLinks"
                                        validateOnChange={false}
                                        render={arrayHelpers => (
                                            <div>
                                                {
                                                    values.photosLinks.map((e, index) => (
                                                        <Row key={`links${index}`}>
                                                            <Col md={6}>
                                                                <FloatingLabel controlId={`photosLinks[${index}]`} label="Вставьте ссылку" className="mt-2">
                                                                    <Form.Control
                                                                        type="text"
                                                                        name={`photosLinks[${index}]`}
                                                                        placeholder="Вставьте ссылку"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        isInvalid={!!errors.photosLinks?.[index] && touched.photosLinks?.[index]}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{errors.photosLinks?.[index]}</Form.Control.Feedback>
                                                                </FloatingLabel>

                                                            </Col>
                                                            <Col md={4} className="d-flex flex-column justify-content-around">
                                                                {!errors.photosLinks?.[index] && <a href={e} target="_blank" rel="noreferrer">Фотографии {index + 1}</a>}
                                                            </Col>
                                                            <Col md={2} className="d-flex flex-column justify-content-around" style={{ fontSize: "1.5em" }}>
                                                                <FontAwesomeIcon icon={faTrashAlt} onClick={() => arrayHelpers.remove(index)} />
                                                            </Col>
                                                        </Row>

                                                    ))
                                                }
                                                <Button variant="outline-dark" className="mt-2" onClick={() => arrayHelpers.push("")}>+</Button>
                                            </div>
                                        )}

                                    />
                                </Col>
                            </Row>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ?
                                    <span>Загрузка <Spinner as="span" animation="border" size="sm" role="status" /></span>
                                    : 'Добавить результаты'
                                }
                            </Button>
                        </Form>
                    )
                }
            </Formik>
            {fetchFailed && <APIPostFailed message={errorMsg} />}
        </Container>
    )
}

export default AddCompetitionResult;