import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { LoginContext } from '../services/LoginContext';
import { useContext } from 'react';

function Footer() {

  const loggedIn = useContext(LoginContext)[0];

  return (
    <footer className="footer-distributed">

      <div className="footer-left">

        <h3 style={{ display: "inline-block" }}>Tennis<span>DV</span></h3>
        <img style={{ paddingBottom: "13px", display: "inline-block" }} alt="Brand" src="/ball48.png" />

        {/* <p className="footer-links">
            <a href="https://itunes.apple.com/us/app/tennisdv/id1089784913?ls=1&mt=8" target="_blank" rel="noreferrer">
              <img src="/MarketIcons/AppStore.svg" alt="AppStore" height="100px" width="150px" />
            </a>

            <a href="https://play.google.com/store/apps/details?id=com.ionicframework.tennisdv271265&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" >
              <img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/images/generic/en-play-badge.png" width="150px" />
            </a>
          </p> */}

        <p className="footer-company-name">Федерация тенниса Хабаровского края &copy; 2024</p>
      </div>

      <div className="footer-center">

        <div>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <p><span>ул. Карла Маркса 62</span> Хабаровск</p>
        </div>

        <div>
          <FontAwesomeIcon icon={faPhone} />
          <p>+7 914 541 35 62</p>
        </div>

        <div>
          <FontAwesomeIcon icon={faEnvelope} />
          <p><a href="mailto:info@tennisdv.ru">info@tennisdv.ru</a></p>
        </div>

        <div>
          {!loggedIn &&
            <Link to="/login">
              Вход для администратора
            </Link>
          }
        </div>
      </div>
    </footer>
  )
}

export default Footer;