import { useEffect, useState } from "react";

const useDelayedRender = () => {
    const [delayed, setDelayed] = useState(true);
    useEffect(() => {
        const timeout = setTimeout(() => setDelayed(false), 500);
        return () => clearTimeout(timeout);
    }, []);
    return fn => delayed ? null : fn();
}

export default useDelayedRender;