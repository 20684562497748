import { Redirect, Route } from "react-router-dom";
import { LoginContext } from "./services/LoginContext";
import { authService } from "./services/auth-service"
import { useContext } from "react";

function ProtectedRoute({component: Component, ...rest}) {
    const loggedIn = useContext(LoginContext)[0];
    return (
        <Route {...rest} render={props => {
            if (authService.isAuthenticated() && loggedIn) {
                return <Component {...props}/>
            } else {
                // console.log("will redirect");
                return <Redirect to={{pathname: "/login"}} />
            }
        }} />
    )
}

export default ProtectedRoute;