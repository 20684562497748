import './UpdateCalendar.css';

import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { faInfoCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import { FieldArray, Formik } from 'formik';

import * as yup from 'yup';
import { useHistory } from 'react-router';
import APIPostFailed from '../../APIFailed/APIPostFailed';
import { authService } from '../../services/auth-service';
import { useContext } from 'react';
import { LoginContext } from '../../services/LoginContext';

function UpdateCalendar(props) {

    class Tournament {
        name = "";
        age = [];
        startDate = undefined;
        endDate = undefined;
    }

    const ageGroups = [
        {
            id: "kids",
            order: 1,
            name: "Дети"
        },
        {
            id: "9to10",
            order: 2,
            name: "9-10 лет"
        },
        {
            id: "under13",
            order: 3,
            name: "До 13 лет"
        },
        {
            id: "under 15",
            order: 4,
            name: "До 15 лет"
        },
        {
            id: "under17",
            order: 5,
            name: "До 17 лет"
        },
        {
            id: "under19",
            order: 6,
            name: "До 19 лет"
        },
        {
            id: "adults",
            order: 7,
            name: "Взрослые"
        }
    ];

    const schema = yup.object().shape({
        year: yup.number().required().min(new Date().getFullYear()),
        schedule: yup.array().of(yup.object({
            name: yup.string().required("Введите название турнира").max(120, "Название турнира не должно превышать 120 символов"),
            age: yup.array().of(yup.string()).min(1, "Выберите хотя бы 1 возрастную группу"),
            startDate: yup.date().required("Выберите дату начала").typeError("Выберите дату начала"),
            endDate: yup.date().required("Выберите дату окончания").typeError("Выберите дату окончания").when("startDate", (startDate, yup) => {
                return startDate && yup.min(startDate, "Дата окончания должна быть позже даты начала")
            })
        })).min(1)
    })

    // concatenate age groups
    const concat = groups => {
        let final = [];
        groups.sort((a, b) => ageGroups.find(e => e.name === a).order - ageGroups.find(e => e.name === b).order);

        let collapseStartIndex = groups.findIndex(e => e.startsWith("До"));
        if (collapseStartIndex > -1) {
            final = final.concat(groups.slice(0, collapseStartIndex));
            let remaining = groups.slice(collapseStartIndex, groups.length);
            let collapseEndIndex = remaining.findIndex(e => !e.startsWith("До"));
            let collapsible = remaining.slice(0, collapseEndIndex > -1 ? collapseEndIndex : remaining.length);
            let collapsed = "";
            if (collapsible.length > 1) {
                collapsible.forEach((e, i, arr) => {
                    if (i === 0) {
                        collapsed += e.replace(" лет", "") + ", ";
                    } else if (i === arr.length - 1) {
                        collapsed += e.replace("До", "");
                    } else {
                        collapsed += e.replace(" лет", "").replace("До", "") + ", ";
                    }
                });
            } else {
                collapsed = collapsible[0];
            }

            final.push(collapsed);
            final = final.concat(remaining.slice(collapseEndIndex > -1 ? collapseEndIndex : remaining.length, remaining.length));

            return final.join(", ");
        } else {
            return groups.join(", ")
        }
    }

    const history = useHistory();
    const setLoggedIn = useContext(LoginContext)[1];
    let [fetchFailed, setFetchFailed] = useState(false);
    let [errorMsg, setErrorMsg] = useState("");

    const submitSchedule = (formData, setSubmitting) => {

        setFetchFailed(false);
        setErrorMsg("");

        // data has to be deep copied, otherwise concat() affects the form values
        let formDataCopy = {
            year: formData.year,
            schedule: formData.schedule.map(e => {
                return {
                    name: e.name,
                    age: concat(e.age),
                    startDate: e.startDate,
                    endDate: e.endDate
                }
            })
        }

        fetch(`${process.env.REACT_APP_API_URL}/schedule`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authService.authHeader()
            },
            body: JSON.stringify(formDataCopy)
        })
            .then(response => response.json().then(data => ({ status: response.status, data: data })))
            .then(({ status, data }) => {
                setSubmitting(false);
                if (status === 201) {
                    let currDate = new Date();
                    props.createToast({
                        title: "Обновить календарь турниров",
                        time: `${currDate.getHours()}:${('0' + currDate.getMinutes()).slice(-2)}`,
                        message: "Календарь турниров был успешно обновлен",
                        show: true
                    });
                    history.push('/siteadmin/home')
                } else if (status === 401) {
                    authService.logout()
                        .then(() => setLoggedIn(false))
                } else {
                    setFetchFailed(true);
                    setErrorMsg(data.error || "Произошла неизвестная ошибка.")
                }
            })
            .catch(err => {
                setSubmitting(false);
                setFetchFailed(true)
                setErrorMsg("Произошла неизвестная ошибка.")
                console.log(err)
            })
    }

    return (
        <Container className="siteadmin-action-container">
            <h1 className="siteadmin-action-heading">Обновить календарь турниров</h1>
            <Alert variant="primary">
                <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: "1.5rem" }} />
                <span style={{ paddingLeft: "1.5rem" }}>Пользователи увидят только последний загруженный календарь за последний год (в том числе будущий)</span>
            </Alert>

            <Formik
                initialValues={{
                    year: (new Date().getFullYear() + 1).toString(),
                    schedule: [new Tournament()]
                }}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) => submitSchedule(values, setSubmitting)}
                validateOnBlur={true}
            // form will be validated on BLUR and CHANGE, validation errors will appear for only touched elements
            // as the isInvalid prop considers both errors and touched states
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isSubmitting,
                    errors

                }) => (

                    <Form noValidate onSubmit={handleSubmit}>

                        {/* Header where user picks the year */}
                        <Form.Group as={Row}>
                            <Col xs="auto">
                                <Form.Label as="h4">Новый календарь турниров на</Form.Label>
                            </Col>
                            <Col xs="auto">
                                <Form.Select
                                    name="year"
                                    onChange={handleChange}
                                    isInvalid={!!errors.year}
                                >
                                    {
                                        [new Date().getFullYear() + 1, new Date().getFullYear()].map((e, index) => {
                                            return (
                                                <option key={index} value={e}>{e}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label as="h4">год</Form.Label>
                            </Col>
                        </Form.Group>
                        <hr className="featurette-divider" />

                        {/* Column headings - no fields here */}
                        <Row className="d-none d-md-flex">
                            <Col xs={5}>
                                <h4>Название турнира</h4>
                            </Col>
                            <Col xs={2}>
                                <h4>Возрастная группа</h4>
                            </Col>
                            <Col xs={2}>
                                <h4>Дата начала</h4>
                            </Col>
                            <Col xs={2}>
                                <h4>Дата окончания</h4>
                            </Col>
                        </Row>

                        {/* Dynamically manage the number of rows in the "schedule" array */}
                        <FieldArray
                            name="schedule"
                            validateOnChange={false}
                            render={arrayHelpers => (
                                <div>
                                    {
                                        values.schedule.map((e, index) => (
                                            <Row key={index} xs={1} style={{ marginBottom: "2em" }}>

                                                {/* Tornament name, 2 lines of text */}
                                                <Col className="d-block d-md-none">
                                                    <h5>Название турнира</h5>
                                                </Col>
                                                <Col md={5} className="mb-4 mb-md-0">
                                                    <Form.Control
                                                        as="textarea"
                                                        name={`schedule[${index}].name`}
                                                        placeholder="Введите название"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={!!errors.schedule?.[index]?.name && touched.schedule?.[index]?.name}
                                                        htmlSize={2} />
                                                    <Form.Control.Feedback type="invalid">{errors.schedule?.[index]?.name}</Form.Control.Feedback>
                                                </Col>

                                                {/* Checkboxes for age groups, multiple selection allowed */}
                                                <Col className="d-block d-md-none">
                                                    <h5>Возрастная группа</h5>
                                                </Col>
                                                <Col md={2} className="mb-4 mb-md-0">
                                                    <Dropdown autoClose="outside">
                                                        <Dropdown.Toggle style={{ width: "100%" }}>
                                                            Возрастные группы
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{ width: "100%" }}>
                                                            {ageGroups.map((e, i) => (
                                                                <Dropdown.Item as="div" key={i}>
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        name={`schedule.${index}.age`}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        id={`${e.id}${index}`}
                                                                        label={e.name}
                                                                        value={e.name} />
                                                                </Dropdown.Item>

                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    {/* Dropdown has no Form.Control, so displaying error message manually */}
                                                    {errors.schedule?.[index]?.age && touched.schedule?.[index]?.age ?
                                                        <div className="invalid-feedback"
                                                            style={{ display: "block" }}> {
                                                                errors.schedule?.[index]?.age}
                                                        </div>
                                                        : null}
                                                </Col>

                                                {/* Start date picker */}
                                                <Col className="d-block d-md-none">
                                                    <h5>Дата начала</h5>
                                                </Col>
                                                <Col md={2} className="mb-4 mb-md-0">
                                                    <Form.Control
                                                        type="date"
                                                        name={`schedule[${index}].startDate`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={!!errors.schedule?.[index]?.startDate && touched.schedule?.[index]?.startDate}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.schedule?.[index]?.startDate}</Form.Control.Feedback>
                                                </Col>

                                                {/* End date picker */}
                                                <Col className="d-block d-md-none">
                                                    <h5>Дата окончания</h5>
                                                </Col>
                                                <Col md={2} className="mb-4 mb-md-0">
                                                    <Form.Control
                                                        type="date"
                                                        name={`schedule[${index}].endDate`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={!!errors.schedule?.[index]?.endDate && touched.schedule?.[index]?.endDate}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{errors.schedule?.[index]?.endDate}</Form.Control.Feedback>
                                                </Col>

                                                {/* Clickable garbage icon */}
                                                <Col xs={{ span: 2, offset: 10 }} md={{ span: 1, offset: 0 }} >
                                                    <div style={{ textAlign: "center", fontSize: "1.5em" }}>
                                                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => arrayHelpers.remove(index)} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))
                                    }

                                    {/* This button adds an empty row to form when clicked */}
                                    <Button
                                        variant="success"
                                        onClick={() => arrayHelpers.push(new Tournament())}
                                        style={{ marginBottom: "2em" }}
                                    >
                                        <strong>+ </strong>Добавить турнир
                                    </Button>
                                </div>
                            )}
                        />
                        <h4>Предварительный просмотр</h4>
                        <Table bordered>
                            <thead>
                                <tr><th>Название турнира</th><th>Возрастная группа</th><th>Даты проведения</th></tr>
                            </thead>
                            <tbody>
                                {

                                    values.schedule.map((e, index) => {

                                        // table row will be highlighted if this row has any errors or has not been touched yet
                                        const validRow = errors => {
                                            return errors.schedule?.[index] || !touched.schedule?.[index];
                                        }

                                        return (
                                            <tr key={index} className={validRow(errors) ? "alert-warning" : null}>
                                                <td>{e.name ? e.name : "Нет названия"}</td>
                                                <td>{e.age.length > 0 ? concat(e.age) : "Нет возрастных групп"}</td>
                                                <td>{e.startDate && e.endDate ? (e.startDate === e.endDate ? `${new Date(e.startDate).toLocaleDateString('ru-RU', { timeZone: 'Europe/London' })}` : `${new Date(e.startDate).toLocaleDateString('ru-RU', { timeZone: 'Europe/London' })}-${new Date(e.endDate).toLocaleDateString('ru-RU', { timeZone: 'Europe/London' })}`) : "Нет дат"}</td>
                                            </tr>
                                        )

                                    })
                                }
                            </tbody>
                        </Table>
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ?
                                <span>Загрузка <Spinner as="span" animation="border" size="sm" role="status" /></span>
                                : 'Обновить календарь'
                            }
                        </Button>
                    </Form>
                )}
            </Formik>
            {fetchFailed && <APIPostFailed message={errorMsg} />}
        </Container>
    )
}

export default UpdateCalendar;