import Carousel from "react-bootstrap/Carousel";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import ResultTable from "./ResultTable";


function CompetitionResult(props) {

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <Collapse appear={props.index <= 2} in={true}>
            <Row style={{ fontWeight: "400", paddingLeft: "15px", marginBottom: "10px" }}>
                <h2 className="mb-2 mt-2">{props.data.name}</h2>
                <Col xs={12}>
                    {
                        props.data.photosLinks.map((link, index) => 
                            <a 
                                key={index} 
                                className="d-block" 
                                style={{width: "max-content"}} 
                                target="_blank" 
                                rel="noreferrer" 
                                href={`${link}`}
                            >
                                {`Фотографии${props.data.photosLinks.length > 1 ? ` (${index + 1})` : ''}`}
                            </a>
                        )
                    }
                </Col>
                {
                    props.data.groups.map(group => {
                        if (group.subgroups.length > 0)
                            return (
                                <Col key={group._id} xs={12}>
                                    <h4 className="mt-3">Возрастная группа {group.name.toLowerCase()}</h4>
                                    {
                                        group.subgroups.map(subgroup => {
                                            return (
                                                <div key={subgroup._id}>
                                                    <p>{subgroup.name}</p>
                                                    <ResultTable data={subgroup.results} />
                                                </div>

                                            )
                                        })
                                    }
                                </Col>

                            )
                        else
                            return (
                                <Col key={group._id} xs={12}>
                                    <h4 className="mt-3">{group.name}</h4>
                                    <ResultTable data={group.results} />
                                </Col>
                            )
                    })
                }
                {props.data.pictures.length > 0 && (
                    <Carousel variant="dark" interval={null}>
                        {
                            props.data.pictures.map((objectKey, index) => (
                                <Carousel.Item key={index}>
                                    <Image
                                        style={{ maxHeight: "50vh" }}
                                        fluid
                                        className="mx-auto d-block"
                                        src={`https://tennisdv-bucket.s3.ca-central-1.amazonaws.com/${objectKey}`}
                                        onClick={() => openInNewTab(`https://tennisdv-bucket.s3.ca-central-1.amazonaws.com/${objectKey}`)}
                                    />
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>
                )
                }
            </Row>
        </Collapse>
    )
}

export default CompetitionResult;