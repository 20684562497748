import Alert from "react-bootstrap/Alert";


function APIPostFailed(props) {
    return (
        <Alert variant="danger">
            <Alert.Heading>Что-то пошло не так!</Alert.Heading>
            <p>{props.message}<br />
                Обновления не будут сохранены.</p>
        </Alert>
    )
}

export default APIPostFailed;