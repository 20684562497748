import { useState, createContext } from "react";

const LoginContext = createContext([[], () => { }]);

const LoginContextProvider = (props) => {
    const [state, setState] = useState(false);
    return (
        <LoginContext.Provider value={[state, setState]}>
            {props.children}
        </LoginContext.Provider>
    )
}

export { LoginContext, LoginContextProvider };