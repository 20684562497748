import Table from "react-bootstrap/Table"

function ResultTable(props) {
    const rowColors = {
        1: "alert-success",
        2: "alert-secondary",
        3: "alert-warning"
    }

    return (
        <Table bordered responsive>
            <thead>
                <tr>
                    <th>Результат</th>
                    <th>Имя</th>
                    {props.data.some(res => res.city) && <th>Город</th>}
                </tr>
            </thead>
            <tbody>
                {
                    props.data.map(res => {
                        return (
                            <tr key={res._id} className={rowColors[res.place]}>
                                <td>{res.place} место</td>
                                <td>{res.player}</td>
                                {res.city && <td>{res.city}</td>}
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    )
}

export default ResultTable;