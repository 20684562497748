import { useRef, useEffect, useContext } from 'react';
import { Route, Switch } from 'react-router';
import './App.css';
import Footer from './Footer/Footer';
import LoginPage from './LoginPage/LoginPage';
import Navigation from './Navigation/Navigation';
import ProtectedRoute from './ProtectedRoute';
import PublicLayout from './PublicLayout/PublicLayout';
import SiteAdminLayout from './SiteAdmin/SiteAdminLayout/SiteAdminLayout';
import { authService } from './services/auth-service';
import { LoginContext } from './services/LoginContext';

function App() {

  const publicLayoutRef = useRef(null);
  const setLoggedIn = useContext(LoginContext)[1];

  const navItemsPublic = [
    {
      text: "Информация",
      route: "/info"
    },
    {
      text: "Новости",
      route: "/news"
    },
    {
      text: "Запись в секцию",
      route: "/groups"
    },
    {
      text: "Соревнования",
      route: "/competition"
    },
    {
      text: "Контакты",
      route: "/contact"
    }
  ];

  const scrollToContent = (path) => {
    setTimeout(() => {
      // scroll to top if site admin page is requested
      if (path.includes('siteadmin')) {
        window.scrollTo({top: 0, behavior: 'smooth'});
      } else { // scroll to main content if public pages are requested
        let coord = publicLayoutRef.current.getBoundingClientRect();
        window.scrollBy({top: coord.top - 90, behavior: 'smooth'})
      }
    }, 200);
  }


  useEffect(() => {

    if (authService.isAuthenticated()) {
      authService.checkToken()
      .then(() => {
        setLoggedIn(true);
      })
      .catch(err => {
        setLoggedIn(false);
      })
    }
  }, [setLoggedIn])

  return (
    <div>
      <Navigation menuItems={navItemsPublic} scrollToContent={scrollToContent}/>
      <div className="spacer" style={{height: "60px"}}/>
      <Switch>
        {/* <Route path="/siteadmin"> */}
        <ProtectedRoute path="/siteadmin" component={SiteAdminLayout} />
          {/* <SiteAdminLayout />
        </Route> */}
        <Route exact path="/login">
          <LoginPage/>
        </Route>
        <Route path="/">
          <PublicLayout  ref={publicLayoutRef}/>
        </Route>
      </Switch>
      <Footer/>
    </div>
  )
}



export default App;
