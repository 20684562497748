import './SiteAdminHome.css';

import { faBullhorn, faCalendarAlt, faListOl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { authService } from '../../services/auth-service';
import { LoginContext } from '../../services/LoginContext';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';


function SiteAdminHome() {
    const setLoggedIn = useContext(LoginContext)[1];
    const history = useHistory();

    const handleLogout = () => {
        authService.logout()
            .then(() => {
                setLoggedIn(false);
                history.push("/");
            });
    }

    return (
        <div>
            <div style={{ background: "url(/Landing/landing3.jpg) center /cover no-repeat", height: "40vh", marginTop: "-10vh" }} className="intro-header"></div>

            <hr className="featurette-divider" />
            <Container className="dashboard">
                <Row xs={1} sm={1} md={3} lg={3} xl={3} style={{ alignItems: "stretch" }}>
                    <Col>
                        <Card>
                            <Card.Header className="text-center">Обновить анонс турнира</Card.Header>
                            <Card.Body>
                                <Card.Title className="text-center"><FontAwesomeIcon icon={faBullhorn} /></Card.Title>
                                <Card.Text style={{ marginTop: "1rem" }}>Обновите текст, отображаемый во вкладке «Новости».</Card.Text>
                                <Link to="/siteadmin/manage/announcement">
                                    <Button variant="primary">Обновить</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className="text-center">Обновить календарь турниров</Card.Header>
                            <Card.Body>
                                <Card.Title className="text-center"><FontAwesomeIcon icon={faCalendarAlt} /></Card.Title>
                                <Card.Text style={{ marginTop: "1rem" }}>Обновите предварительный календарь турниров.</Card.Text>
                                <Link to="/siteadmin/manage/calendar">
                                    <Button variant="primary">Обновить</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className="text-center">Добавить результаты соревнований</Card.Header>
                            <Card.Body>
                                <Card.Title className="text-center"><FontAwesomeIcon icon={faListOl} /></Card.Title>
                                <Card.Text style={{ marginTop: "1rem" }}>Добавьте результаты прошедших соревнований.</Card.Text>
                                <Link to="/siteadmin/manage/result">
                                    <Button variant="primary">Добавить</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="justify-content-end pe-md-3 mb-2">
                    <Col md="auto" className="flex-shrink-1 flex-grow-0">
                        <Link to="/siteadmin/change-password">
                            <Button
                                className="w-100"
                                variant="secondary"

                            >Сменить пароль
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row className="justify-content-end pe-md-3">
                    <Col md="auto" className="flex-shrink-1 flex-grow-0">
                        <Button
                            className="w-100"
                            variant="danger"
                            onClick={handleLogout}
                        >Выход
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SiteAdminHome;