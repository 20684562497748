import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useState, useContext } from "react";
import APIPostFailed from "../../APIFailed/APIPostFailed";
import { useHistory } from "react-router";

import * as yup from 'yup';
import { Formik } from "formik";
import { authService } from "../../services/auth-service";
import { LoginContext } from "../../services/LoginContext";

function AddAnnouncement(props) {

    let schema = yup.object().shape({
        message: yup.string().required("Это поле не может быть пустым.").max(300, "Превышен лимит из 300 символов.")
    });


    const helpText = "С 22.03 по 26.03 состоится Первенство ДФО среди юношей и девушек в возрастной категории до 17 лет."

    const history = useHistory()
    const setLoggedIn = useContext(LoginContext)[1]
    let [fetchFailed, setFetchFailed] = useState(false);
    let [errorMsg, setErrorMsg] = useState("");

    const submitAnnouncement = (formData, setSubmitting) => {

        setFetchFailed(false);
        setErrorMsg("");
        fetch(`${process.env.REACT_APP_API_URL}/announcement`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authService.authHeader()
            },
            body: JSON.stringify({ message: formData.message })
        })
            .then(response => response.json().then(data => ({ status: response.status, data: data })))
            .then(({ status, data }) => {
                setSubmitting(false);
                if (status === 201) {
                    let currDate = new Date();
                    props.createToast({
                        title: "Обновить анонс турнира",
                        time: `${currDate.getHours()}:${('0' + currDate.getMinutes()).slice(-2)}`,
                        message: "Анонс турнира был успешно обновлен",
                        show: true
                    });
                    history.push('/siteadmin/home')
                } else if (status === 401) {
                    authService.logout()
                        .then(() => setLoggedIn(false))
                } else {
                    setFetchFailed(true)
                    setErrorMsg(data.error || "Произошла неизвестная ошибка.")
                }
            })
            .catch(err => {
                setSubmitting(false)
                setFetchFailed(true)
                setErrorMsg("Произошла неизвестная ошибка.")
                console.log(err)
            })
    }

    return (
        <Container className="siteadmin-action-container">
            <h1 className="siteadmin-action-heading">Обновить анонс турнира</h1>
            <Alert variant="primary">
                <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: "1.5rem" }} />
                <span style={{ paddingLeft: "1.5rem" }}>Добавленный анонс сразу заменит текущий анонс во вкладке «Новости»</span>
            </Alert>
            <Formik
                validateOnBlur={true}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) => submitAnnouncement(values, setSubmitting)}
                initialValues={{
                    message: ""
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isSubmitting,
                    errors
                }) => (
                    <Form noValidate onSubmit={handleSubmit} style={{ marginBottom: "1rem" }}>
                        <Form.Group controlId="message">
                            <Form.Label>Текст анонса</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="message"
                                placeholder="Введите новый текст о предстоящем турнире"
                                rows={5}
                                defaultValue={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.message && !errors.message}
                                isInvalid={!!errors.message} />
                            <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                            <Form.Text id="announcementHelp">Например: {helpText}</Form.Text>
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ?
                                <span>Загрузка <Spinner as="span" animation="border" size="sm" role="status" /></span>
                                : 'Обновить'
                            }
                        </Button>
                    </Form>
                )}
            </Formik>
            {fetchFailed && <APIPostFailed message={errorMsg} />}
        </Container>
    )
}

export default AddAnnouncement;