import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Container from "react-bootstrap/Container";
import Placeholder from "react-bootstrap/Placeholder";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import APIGetFailed from "../APIFailed/APIGetFailed";
import useDelayedRender from "../hooks";

function Schedule() {

    const [schedule, setSchedule] = useState({});
    const [fetchFailed, setFetchFailed] = useState(false);
    const delayedRender = useDelayedRender();

    // fetches schedule
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/schedule`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                setSchedule(data);
            })
            .catch(err => {
                setFetchFailed(true);
            });
    }, []);

    if (fetchFailed) {
        return <APIGetFailed data="календарь турниров" />
    }
    else if (!schedule.schedule) {
        return delayedRender(() =>
            <Collapse in={true} appear={true}>
                <Container>
                    <Row>
                        <Col>
                            <h4>Предварительный календарь турниров</h4>
                            <hr className="featurette-divider" />
                            <Table striped bordered responsive hover>
                                <thead>
                                    <tr><th>Даты проведения</th><th>Название турнира</th><th>Возрастная группа</th></tr>
                                </thead>
                                <tbody>
                                    {/* show table with placeholders if still loading */}
                                    <tr>
                                        <Placeholder as="td" animation="glow">
                                            <Placeholder xs={4} />
                                        </Placeholder>
                                        <Placeholder as="td" animation="glow">
                                            <Placeholder xs={8} />
                                        </Placeholder>
                                        <Placeholder as="td" animation="glow">
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </Collapse>
        )
    }


    return (
        <Collapse in={true} appear={true}>
            <Container className="mb-4">
                <Row>
                    <Col>
                        <h4>Предварительный календарь турниров на {schedule.year} год</h4>
                        <hr className="featurette-divider" />
                        <Table striped bordered responsive hover>
                            <thead>
                                <tr><th>Даты проведения</th><th>Название турнира</th><th>Возрастная группа</th></tr>
                            </thead>
                            <tbody>
                                {/* populate table with data if available */}
                                {schedule.schedule.map(e => {
                                    return (
                                        <tr key={e._id}>
                                            <td>
                                                {e.startDate === e.endDate ? 
                                                    `${(new Date(e.startDate)).toLocaleDateString('ru-RU', { timeZone: 'Europe/London' })}`
                                                    : `${(new Date(e.startDate)).toLocaleDateString('ru-RU', { timeZone: 'Europe/London' })}-${(new Date(e.endDate)).toLocaleDateString('ru-RU', { timeZone: 'Europe/London' })}`
                                                }
                                            </td>
                                            <td>{e.name}</td>
                                            <td>{e.age}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </Collapse>
    )

}

export default Schedule;