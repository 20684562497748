import './SiteAdminLayout.css'

import { Route, Switch } from "react-router";
import AddAnnouncement from "../AddAnnouncement/AddAnnouncement";
import SiteAdminHome from "../SiteAdminHome/SiteAdminHome";
import { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import UpdateCalendar from '../UpdateCalendar/UpdateCalendar';
import AddCompetitionResult from '../AddCompetitionResult/AddCompetitionResult';
import ChangePassword from '../ChangePassword/ChangePassword';

function SiteAdminLayout() {

    const [toasts, setToasts] = useState([]);

    let createToast = (toastObj) => {
        let toastsCopy = toasts
        toastsCopy.push(toastObj)
        setToasts([...toastsCopy])
    }


    let toggleToast = (index) => {
        let toastsCopy = toasts
        toastsCopy[index].show = false
        setToasts([...toastsCopy])
    }

    return (
        <div>
            <ToastContainer position="top-end" style={{ marginTop: "70px", marginRight: "10px" }}>
                {
                    toasts.map((t, index) =>
                        <Toast
                            key={index}
                            bg="light"
                            onClose={() => toggleToast(index)}
                            show={t.show}>
                            <Toast.Header closeButton={true}>
                                <strong className="me-auto">{t.title}</strong>
                                <small>{t.time}</small>
                            </Toast.Header>
                            <Toast.Body>{t.message}</Toast.Body>
                        </Toast>
                    )
                }
            </ToastContainer>

            <Switch>
                <Route exact path="/siteadmin/home">
                    <SiteAdminHome />
                </Route>
                <Route exact path="/siteadmin/change-password">
                    <ChangePassword createToast={createToast} />
                </Route>
                <Route exact path="/siteadmin/manage/announcement">
                    <AddAnnouncement createToast={createToast} />
                </Route>
                <Route exact path="/siteadmin/manage/calendar">
                    <UpdateCalendar createToast={createToast} />
                </Route>
                <Route exact path="/siteadmin/manage/result">
                    <AddCompetitionResult createToast={createToast} />
                </Route>
            </Switch>

        </div>
    )
}

export default SiteAdminLayout;