import { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import Container from "react-bootstrap/Container";
import Placeholder from "react-bootstrap/Placeholder";
import Table from "react-bootstrap/Table";
import CompetitionResult from "./CompetitionResult";
import ListPagination from "./ListPagination";
import APIGetFailed from "../APIFailed/APIGetFailed";
import useDelayedRender from "../hooks";

function Competitions() {

    // pagination state is maintained by this component as parent
    let [years, setYears] = useState([])
    let [activeYear, setActiveYear] = useState();


    let [compResults, setCompResults] = useState([])

    let [fetchFailed, setFetchFailed] = useState(false)

    const delayedRender = useDelayedRender();

    // get available years for pagination
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/competitions/years`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                setYears(data);
                setActiveYear(Math.max(...data));
            })
            .catch(err => {
                setFetchFailed(true);
                console.log(err);
            })
    }, [])


    // get competition results every time activeYear changes
    useEffect(() => {
        if (activeYear)
            fetch(`${process.env.REACT_APP_API_URL}/competitions/${activeYear}`)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then(data => {
                    setCompResults(data);
                })
                .catch(err => {
                    setFetchFailed(true);
                    console.log(err)
                });
    }, [activeYear])

    // click handler passed to ListPagination children
    function handlePagination(year) {
        setActiveYear(year);
    }

    // render error component if fetch failed
    if (fetchFailed) {
        return <APIGetFailed data="результаты соревнований" />
    }
    else if (compResults.length === 0) {
        return delayedRender(() =>
            <Collapse in={true} appear={true}>
                <Container>
                    <hr className="featurette-divider" />
                    <Placeholder as="h2" animation="glow">
                        <Placeholder className="w-75" />
                    </Placeholder>
                    <Placeholder as="h4" animation="glow">
                        <Placeholder className="w-50" />
                    </Placeholder>
                    <Table bordered responsive>
                        <thead>
                            <tr><th>Результат</th><th>Имя</th><th>Город</th></tr>
                        </thead>
                        <tbody>
                            <tr>
                                <Placeholder as="td" animation="glow">
                                    <Placeholder xs={4} />
                                </Placeholder>
                                <Placeholder as="td" animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as="td" animation="glow">
                                    <Placeholder xs={4} />
                                </Placeholder>
                            </tr>
                        </tbody>
                    </Table>
                </Container>
            </Collapse>
        )
    }

    return (
        <Container className="ps-md-5 pe-md-5">
            <ListPagination years={years} activeYear={activeYear} paginationHandler={handlePagination} />
            {
                compResults.map((res, index) => <CompetitionResult key={res._id} data={res} index={index} />)
            }
            <ListPagination years={years} activeYear={activeYear} paginationHandler={handlePagination} />
        </Container>
    )
}

export default Competitions;