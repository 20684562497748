import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = (_props) => <FontAwesomeIcon icon={faMapMarkerAlt} className="fa-5x" style={{color: "red"}}/>

function Map(props) {

    return (
        <div style={{height: '50vh' }}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: "AIzaSyDR3b24Nwq1J73YbdZE4MHEI0g6x8DDb3g",
                    language: 'ru',
                    region: 'ru'
                }}
                defaultCenter={props.coordinates}
                defaultZoom={15}>
                    <AnyReactComponent lat={props.coordinates.lat} lng={props.coordinates.lng}/>
            </GoogleMapReact>
        </div>
    )
}

export default Map;