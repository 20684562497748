import { useState, useContext } from 'react';
import { Formik } from "formik";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { authService } from "../../services/auth-service";

import * as yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { LoginContext } from '../../services/LoginContext';
import { useHistory } from 'react-router-dom';

function ChangePassword(props) {
    const [fetchError, setFetchError] = useState();
    const setLoggedIn = useContext(LoginContext)[1];
    const history = useHistory();

    const schema = yup.object().shape({
        password: yup.string().required("Это обязательное поле"),
        newPassword: yup.string().required("Это обязательное поле").min(6, "Пароль должен содержать от 6 символов"),
        confirmPassword: yup.string().oneOf([yup.ref("newPassword"), null], "Пароли не совпадают").required("Это обязательное поле")
    });


    const handlePasswordChange = (formData, setSubmitting) => {
        setFetchError(undefined);
        authService.changePassword(formData)
            .then(() => {
                setSubmitting(false);
                let currDate = new Date();
                props.createToast({
                    title: "Сменить пароль",
                    time: `${currDate.getHours()}:${('0' + currDate.getMinutes()).slice(-2)}`,
                    message: "Ваш пароль был успешно обновлен",
                    show: true
                });
                history.push('/siteadmin/home')
            })
            .catch(err => {
                setSubmitting(false)

                if (err === 401) {
                    setLoggedIn(false);
                } else {
                    setFetchError(err.data.error);
                }
            })

    }

    return (
        <Container>
            <Row style={{ textAlign: "center" }}>
                <h2 className="mt-5">Сменить пароль</h2>
            </Row>
            <Row className="justify-content-around">
                <Col as={Card} md={6} className="shadow m-5">
                    <Card.Body>
                        <Card.Title className="mb-4 mt-3">Введите новый пароль</Card.Title>
                        <Formik
                            initialValues={{
                                password: "",
                                newPassword: "",
                                confirmPassword: ""
                            }}
                            validationSchema={schema}
                            validateOnBlur={true}
                            onSubmit={(values, { setSubmitting }) => handlePasswordChange(values, setSubmitting)}
                        >
                            {
                                ({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isSubmitting,
                                    errors

                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <FloatingLabel
                                            controlId="password"
                                            label="Старый пароль"
                                            className="mb-4"
                                        >
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                placeholder="Старый пароль"
                                                isInvalid={!!errors.password && touched.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <FloatingLabel
                                            controlId="newPassword"
                                            label="Новый пароль"
                                            className="mb-4"
                                        >
                                            <Form.Control
                                                type="password"
                                                name="newPassword"
                                                placeholder="Новый пароль"
                                                isInvalid={!!errors.newPassword && touched.newPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <FloatingLabel
                                            controlId="confirmPassword"
                                            label="Подтвердите новый пароль"
                                            className="mb-4"
                                        >
                                            <Form.Control
                                                type="password"
                                                name="confirmPassword"
                                                placeholder="Подтвердите новый пароль"
                                                isInvalid={!!errors.confirmPassword && touched.confirmPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <div className="d-grid d-md-block">
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                className="mb-3"
                                                size="lg"
                                                style={{ minWidth: "120px" }}
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ?
                                                    <span>Загрузка <Spinner as="span" animation="border" size="sm" role="status" /></span>
                                                    : 'Готово'
                                                }
                                            </Button>
                                            {fetchError && <Alert variant="danger">
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                <span className="ps-2">{fetchError}</span>
                                            </Alert>}
                                        </div>
                                    </Form>
                                )}
                        </Formik>
                    </Card.Body>
                </Col>
            </Row>
        </Container>
    )
}

export default ChangePassword;