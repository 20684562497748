import { BehaviorSubject } from 'rxjs';

const tokenSubject = new BehaviorSubject(localStorage.getItem('tennisdv-token'));

let login = (username, password) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/user/login`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        })
        .then(response => response.json().then(data => ({status: response.status, data: data})))
        .then(({status, data}) => {
            if (status === 200) {
                localStorage.setItem('tennisdv-token', data.token);
                tokenSubject.next(data.token);
                resolve();
            } else {
                reject(data.error)
            }
        })
        .catch(err => {
            reject(err)
        })

    })
}

let checkToken = () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/user/check-token`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                "Authorization": authHeader()
            }
        })
        .then(response => {
            if (response.status === 200) {
                resolve();
            } else {
                localStorage.clear();
                tokenSubject.next(null);
                reject(response.status);
            }
        })
        .catch(err => {
            reject(err)
        })

    })
}

let changePassword = (formData) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/user/change-password`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                "Authorization": authHeader()
            },
            body: JSON.stringify({
                password: formData.password,
                newPassword: formData.newPassword,
                confirmPassword: formData.confirmPassword
            })
        })
        .then(response => response.json().then(data => ({status: response.status, data: data})))
        .then(({status, data}) => {
            if (status === 200) {
                resolve();
            } else if (status === 401) {
                localStorage.clear();
                tokenSubject.next(null);
                reject(status);
            } else {
                reject({
                    status: status,
                    data: data
                })
            }
        })
        .catch(err => {
            reject(err);
        })
    })
}

let logout = () => {
    return new Promise((resolve, reject) => {
        localStorage.clear();
        tokenSubject.next(null);
        resolve();
    })
}

const authHeader = () => {
    const currentToken = tokenSubject.value;
    if (currentToken) {
        return `Bearer ${localStorage.getItem('tennisdv-token')}`
    } else {
        return ""
    }
}

const isAuthenticated = () => {
    return tokenSubject.value ? true : false;
}


export const authService = {
    login,
    checkToken,
    changePassword,
    logout,
    authHeader,
    isAuthenticated,
    token: tokenSubject
}