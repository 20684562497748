import { useContext } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import { LoginContext } from "../services/LoginContext";

function Navigation(props) {

    const loggedIn = useContext(LoginContext)[0];


    return (

        <Navbar collapseOnSelect fixed="top" expand="lg" bg="light" variant="light" onSelect={(path, evt) => props.scrollToContent(path)}>
            <Container >
                <Navbar.Brand>
                    <h5 style={{ display: "inline-block" }} id="fedHeader">Федерация тенниса Хабаровского края</h5>{' '}
                    <img style={{ display: "inline-block" }} alt="Brand" src="/ball48.png" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                    <Nav>
                        {props.menuItems.map((item, index) =>
                            <Nav.Item key={index}>
                                <LinkContainer to={item.route}>
                                    <Nav.Link>{item.text}</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                        )}
                        {loggedIn &&
                            <Nav.Item>
                                <LinkContainer to="/siteadmin/home">
                                    <Nav.Link>Управление сайтом</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation;