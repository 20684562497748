import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Info from '../Info/Info';
import News from '../News/News';
import Groups from '../Groups/Groups';
import { Switch, Route, Redirect } from 'react-router';
import Contact from '../Contact/Contact';
import Competitions from '../Competitions/Competitions';
import { forwardRef } from 'react';

const PublicLayout = forwardRef((props, ref) => {

    return (
        <div>
            <div style={{ background: "url(/Landing/landing3.jpg) center /cover no-repeat" }} className="intro-header">
                <Container>

                    <Row>
                        <Col>
                            <div className="intro-message">
                                <h1 style={{ textShadow: "2px 2px 35px rgba(0, 0, 0, 0.6)" }}>Федерация тенниса Хабаровского края</h1>
                                <h3 style={{ textShadow: "2px 2px 25px rgba(0, 0, 0, 0.6)" }}>Присоединяйтесь к крупнейшему сообществу теннисистов Дальнего Востока</h3>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
            <Container ref={ref}>
                <hr className="featurette-divider" />
            </Container>

            <Switch>
                <Route exact path='/'>
                    <Redirect to='/info' />
                </Route>
                <Route exact path='/info'>
                    <Info />
                </Route>
                <Route exact path='/news'>
                    <News />
                </Route>
                <Route exact path="/groups">
                    <Groups />
                </Route>
                <Route exact path="/competition">
                    <Competitions />
                </Route>
                <Route exact path="/contact">
                    <Contact />
                </Route>
            </Switch>
        </div>
    )
})

export default PublicLayout;