import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import APIGetFailed from "../APIFailed/APIGetFailed";


function Announcement() {

    let [announcement, setAnnouncement] = useState();

    const [fetchFailed, setFetchFailed] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/announcement`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                setAnnouncement(data);
            })
            .catch(err => {
                setFetchFailed(true);
                console.log(err);
            })
    }, [])

    if (fetchFailed) {
        return <APIGetFailed data="анонс ближайшего турнира" />
    }

    return (
        <Collapse in={true} appear={true}>
            <Container>
                <h4>Анонс ближайшего турнира</h4>
                <Row>
                    <Col md={7}>
                        <p>{announcement?.message}</p>
                    </Col>
                    <Col md={5}>
                        <Image src="Landing/landing4.jpg" alt="Краевой парный турнир в смешанном разряде, посвященный международному «Дню семьи»" rounded fluid />
                    </Col>
                </Row>
                <hr className="featurette-divider" />
            </Container>
        </Collapse>
    )
}

export default Announcement;