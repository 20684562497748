import Announcement from './Announcement';
import Schedule from '../Schedule/Schedule'

function News() {

    return (
        <div>
            <Announcement/>
            <Schedule/>
        </div>
    )
}

export default News;