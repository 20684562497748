import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";
import Row from "react-bootstrap/Row";

function ListPagination(props) {

    return (
        <Row>
            <Col>
                <Pagination style={{ justifyContent: "center" }}>
                    <Pagination.Prev
                        disabled={props.activeYear === Math.max(...props.years)}
                        onClick={() => props.paginationHandler(props.activeYear + 1)}
                    />
                    {
                        props.years.slice(props.years.findIndex(y => y === props.activeYear)).slice(0,3).map(item => {
                            return (
                                <Pagination.Item key={item}
                                    active={item === props.activeYear}
                                    onClick={() => { props.paginationHandler(item) }}>
                                    {item}
                                </Pagination.Item>
                            )
                        })
                    }
                    {props.activeYear > 2018 && <Pagination.Ellipsis disabled/>}
                    <Pagination.Next 
                        disabled={props.activeYear === Math.min(...props.years)}
                        onClick={() => props.paginationHandler(props.activeYear - 1)}
                    />
                </Pagination>
            </Col>
        </Row>
    )
}

export default ListPagination;