import { useState, useContext } from 'react';
import { Formik } from "formik";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { authService } from "../services/auth-service";

import * as yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from 'react-router-dom';
import { LoginContext } from '../services/LoginContext';

function LoginPage() {
    const [loginError, setLoginError] = useState();
    const [loggedIn, setLoggedIn] = useContext(LoginContext);

    const schema = yup.object().shape({
        username: yup.string().required("Это обязательное поле"),
        password: yup.string().required("Это обязательное поле")
    })


    const handleLogin = (formData, setSubmitting) => {
        setLoginError(undefined);
        authService.login(formData.username, formData.password)
            .then(() => {
                setLoggedIn(true);
            })
            .catch(err => {
                setLoggedIn(false);
                setLoginError(err);
                setSubmitting(false)
            })

    }

    if (loggedIn) {
        return <Redirect to="/siteadmin/home" />;
    }

    return (
        <Container>
            <Row style={{ textAlign: "center" }}>
                <h2 className="mt-5">Вход для администратора</h2>
            </Row>
            <Row className="justify-content-around">
                <Col as={Card} md={6} className="shadow m-5">
                    <Card.Body>
                        <Card.Title className="mb-4 mt-3">Войдите в ваш аккаунт</Card.Title>
                        <Formik
                            initialValues={{
                                username: "",
                                password: ""
                            }}
                            validationSchema={schema}
                            validateOnBlur={true}
                            onSubmit={(values, { setSubmitting }) => handleLogin(values, setSubmitting)}
                        >
                            {
                                ({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isSubmitting,
                                    errors

                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <FloatingLabel
                                            controlId="username"
                                            label="Username"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                name="username"
                                                placeholder="Username"
                                                isInvalid={!!errors.username && touched.username}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <FloatingLabel
                                            controlId="password"
                                            label="Password"
                                            className="mb-4"
                                        >
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                isInvalid={!!errors.password && touched.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <div className="d-grid d-md-block">
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                className="mb-3"
                                                size="lg"
                                                style={{ minWidth: "120px" }}
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ?
                                                    <span>Загрузка <Spinner as="span" animation="border" size="sm" role="status" /></span>
                                                    : 'Войти'
                                                }
                                            </Button>
                                            {loginError && <Alert variant="danger">
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                <span className="ps-2">{`${loginError}`}</span>
                                            </Alert>}
                                        </div>
                                    </Form>
                                )}
                        </Formik>
                    </Card.Body>
                </Col>
            </Row>
        </Container>
    )
}

export default LoginPage;