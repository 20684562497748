import './Groups.css';
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";


function Groups() {

  return (
    <Container id="groups">
      <h4>Запись в секцию</h4>
      <hr className="featurette-divider" />
      <Row>
        <Col md={7}>
          <h2>Более подробную информацию можно получить по телефону +7 914 541 35 62</h2>
          <p>Мы принимаем в секцию взрослых и детей от 6 лет любого уровня.Наши опытные тренеры уделяют особое внимание технике игры, а также физической подготовки. Мы всегда рады Вам и Вашим детям! </p>
        </Col>
        <Col md={5}>
          <Image src="Landing/landing2.jpg" alt="Generic placeholder" rounded fluid />
        </Col>
      </Row>
    </Container>
  )
}

export default Groups;