import './Info.css';
import Schedule from '../Schedule/Schedule'
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

function Info() {
    return (
        <Container id="info">
            <p>Формы заявок на турниры</p>
            <a target="_blank" rel="noreferrer" href="https://tennisdv-bucket.s3.ca-central-1.amazonaws.com/singles.xlsx" download={`singles.xlsx`}>Заявка одиночная</a><br />
            <a target="_blank" rel="noreferrer" href="https://tennisdv-bucket.s3.ca-central-1.amazonaws.com/doubles.xlsx" download={`doubles.xlsx`}>Заявка парная</a>
            <hr className="featurette-divider" />
            <Schedule />
            <Row id="one">
                <Col md={7}>
                    <h2>Информация о кортах </h2>
                    <p>Наши теннисные корты открыты для любителей спорта всех возрастов в любое время года. Зимой корты располагаются в ТЦ "Стрелка" в теннисном клубе "Smash Club" по адресу ул. Карла Маркса, д.202. В летний период мы ждем Вас на территории парка Динамо по адресу ул. Карла Маркса, д.62.</p>
                </Col>
                <Col md={5}>
                    <Image src="Landing/landing1.jpg" alt="Generic placeholder" rounded fluid />
                </Col>
            </Row>

            <hr className="featurette-divider" />

            <Row id="two">
                <Col md={5}>
                    <Image src="Landing/landing2.jpg" alt="Generic placeholder" rounded fluid />
                </Col>
                <Col>
                    <h2>Запись в секцию </h2>
                    <p>Мы принимаем в секцию детей от 6 лет любого уровня. Наши опытные тренеры уделяют особое внимание технике игры, а также физической подготовке. Более подробную информацию можно получить в разделе "Запись в секцию". </p>
                </Col>
            </Row>

            <hr className="featurette-divider" />

            <Row id="three">
                <Col>
                    <h2>Аренда кортов </h2>
                    <p>Для наших гостей предоставляется услуга аренды теннисных кортов и в летнее и в зимнее время.</p>
                </Col>
                <Col md={5}>
                    <Image src="Landing/landing3.jpg" alt="Generic placeholder" rounded fluid />
                </Col>
            </Row>
        </Container>
    )
}

export default Info;