import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


function APIGetFailed(props) {

    return (
        <Container>
            <Row style={{ justifyContent: "center" }}>
                <Col md="auto" style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#ffcc00", fontSize: "3em" }} />
                    <p>Не удалось загрузить {props.data}</p>
                </Col>
            </Row>
        </Container>
    )
}

export default APIGetFailed;