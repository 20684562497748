import Tabs from "react-bootstrap/Tabs";
import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Map from '../Map/Map'

function Contact() {

  // location data
  const locations = [
    {
      season: "summer",
      title: "Летний сезон",
      address: "г. Хабаровск, ул. Карла Маркса 62. Территория парка Динамо",
      email: "info@tennisdv.ru",
      phone: "+7 914 541 35 62",
      coordinates: {
        lat: 48.482232,
        lng: 135.079434
      }
    },
    {
      season: "winter",
      title: "Зимний сезон",
      address: "г. Хабаровск, ул. Cоветская, 1А. Универсальный краевой спортивный комплекс",
      email: "info@tennisdv.ru",
      phone: "+7 914 541 35 62",
      coordinates: {
        lat: 48.476217,
        lng: 135.048986
      }
    }
  ]

  // determines which tab to select upon loading based on season
  const getSeason = () => {
    let date = new Date();
    if (date.getMonth() >= 4 && date.getMonth() <= 10)
      return "summer"
    else
      return "winter"
  }

  return (
    <Container>
      <h2>Наш адрес</h2>
      <Tabs defaultActiveKey={getSeason()}>
        {locations.map((loc, index) => {
          return (
            <Tab eventKey={loc.season} title={loc.title} key={index}>
              <h3>{loc.address}</h3>
              <p>email: {loc.email}</p>
              <p>Телефон: {loc.phone}</p>
              <Map coordinates={loc.coordinates} />
            </Tab>
          )
        })}

      </Tabs>
    </Container>
  )
}

export default Contact;